<template >
  <div class="item-info">
    <div class="item-sub" v-if="logged_in">
      <div
        class="row price-and-discount"
        v-if="
          parseFloat(price.discount_percentage) > 0 &&
          price.gross_price > 0 &&
          (price.net_price && price.gross_price != price.net_price)
        "
      >
        <div class="col-xs-12 col-sm-12 col-md-12 prev-price">
          <div class="line-through">
            {{ $t("gross_price") }}:
            {{ formatPrice(price.gross_price) }}
          </div>

          <div class="orange-text bold">
            {{ price.discount_percentage }}%
            <i class="fa fa-info-circle" aria-hidden="true" v-tooltip="'Kortingsgroep: ' + discount_group"></i>
          </div>
        </div>
      </div>
      <div class="row price">
        <!-- {{price }} -->
        <div class="col-xs-12 col-sm-12 col-md-12 total-price">
          
          <h1 v-if="price.net_price > 0">
            {{ formatPrice(price.net_price) }}
          </h1>
          <h1 v-else-if="price.gross_price > 0">{{ formatPrice(price.gross_price) }}</h1>
          <h1 v-else>{{ $t('on_request') }}</h1>
          <div class="pieces" v-if="price.utilization_order_ratio > 0">
            <span>({{ $t("ex_vat") }})</span>
            {{ $t("per") }}
            {{ price.utilization_order_ratio }} {{ $t("pieces") }}
          </div>
          <div class="pieces" v-else-if="(price.net_price > 0 || price.gross_price > 0)">{{ $t("per_piece") }}</div>
        </div>
      </div>
      <!-- stock -->
      <div class="row stock" v-loading="loading">
        <div
          class="col-xs-12 col-sm-12 col-md-12"
          v-if="stock_info && stock_info.AvailableInventory <= 0.1"
        >
          <h5>
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            {{ $t("not_in_stock") }}
          </h5>
          <div class="foot-note">
            {{ $t("not_in_stock_message") }}
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12"
          v-else-if="stock_info && stock_info.AvailableInventory < qty"
        >
          <h5 class="green-text">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ $t("partially_in_stock") }}
          </h5>
          <div class="foot-note">
            <span v-html="$t('product_message_1')"></span> {{ stock_info.AvailableInventory }}
            <span v-html="$t('product_message_2')"></span>{{ qty - stock_info.AvailableInventory
            }}<span v-html="$t('product_message_3')"></span>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12"
          v-else-if="stock_info && stock_info.AvailableInventory >= qty"
        >
          <h5 class="green-text">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ $t("in_stock") }}
          </h5>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12" v-else-if="stock_info">
          <h5 class="orange-text">
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            {{ $t("stock_on_request") }}
          </h5>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12" v-else-if="!stock_info">
          <h5>
            <i class="fa fa-times-circle" aria-hidden="true"></i>
            {{ $t("backend_error") }}
          </h5>
        </div>
      </div>
      <!-- amount -->
    </div>
    <div class="item-sub" v-if="!logged_in">
      <div class="row price" v-if="language_code != 'CZ'">
        <div class="col-xs-12 col-sm-12 col-md-12 total-price">
          {{ $t("gross_price") }}: <br/>
          <h1 v-if="price.gross_price > 0">
            {{ formatPrice(price.gross_price) }}
          </h1>
          <h1 v-else>{{ $t('on_request') }}</h1>
          <div class="pieces" v-if="price.utilization_order_ratio > 0">
            {{ $t("per") }} {{ price.utilization_order_ratio }} {{ $t("pieces") }}
          </div>
          <div class="pieces" v-else-if="(price.net_price > 0 || price.gross_price > 0)">{{ $t("per_piece") }}</div>
        </div>
      </div>
      <div class="row stock" v-loading="loading">
        <div
          class="col-xs-12 col-sm-12 col-md-12"
          v-if="stock_info && stock_info.AvailableInventory >= qty"
        >
          <h5 class="green-text">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ $t("in_stock") }}
          </h5>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12"
          v-else
        >
          <h5 class="orange-text">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ $t("stock_on_request") }}
          </h5>
        </div>
      </div>
    </div>
    <div class="row amount-order">
        <div class="hidden-xs hidden-sm col-md-2">
          <input class="amount mouse-pointer" v-model="qty" />
        </div>
        <div class="hidden-xs hidden-sm col-md-1">
          <div class="plus mouse-pointer" @click="plus">+</div>
          <div class="minus mouse-pointer" @click="minus">-</div>
        </div>
        <div class="col-xs-8 col-sm-8 hidden-md hidden-lg">
          <div class="minus mouse-pointer" @click="minus">-</div>
          <input class="amount" v-model="qty" />
          <div class="plus mouse-pointer" @click="plus">+</div>
        </div>
        <!-- order button -->
        <div class="col-xs-4 col-sm-4 col-md-9">
          <div class="order-button">
            <button
              type="button"
              class="button-green-slide width-100"
              @click="addItem"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-2 shopping-icon">
                  <i
                    class="fa fa-shopping-cart"
                    aria-hidden="true"
                    v-if="!shopping"
                  ></i>
                  <i class="fas fa-check" v-else></i>
                </div>
                <div
                  class="hidden-xs hidden-sm col-md-8 text"
                  v-if="!shopping && logged_in"
                >
                  {{ $t("add_to_cart") }}
                </div>
                <div
                  class="hidden-xs hidden-sm col-md-8 text"
                  v-else-if="!shopping && !logged_in"
                >
                  {{ $t("add_to_estimate_list") }}
                </div>
                <div
                  class="hidden-xs hidden-sm col-md-8 text"
                  v-else-if="shopping && !logged_in"
                >
                  {{ $t("added_to_estimate_list") }}
                </div>
                <div
                  class="hidden-xs hidden-sm col-md-8 text"
                  v-else-if="shopping && logged_in"
                >
                  {{ $t("added_to_cart") }}
                </div>
                <div
                  class="hidden-xs hidden-sm col-md-8 text"
                  v-else
                >
                  {{ $t("add_to_estimate_list") }}
                </div>
                <div class="hidden-xs hidden-sm col-md-2 chevron-icon">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="row" v-if="logged_in">
        <div class="col-md-12">
          <template
            v-if="stock_info.AvailableInventory > 0"
          >
            <div class="list-item text-left" v-if="todayInTime">
              <span v-html="$t('send_date')"></span>: {{ $t("today_ordered_before") }}
            </div>
            <div class="list-item text-left" v-else>
              <span v-html="$t('send_date')"></span>: {{ $t("next_working_day") }}
            </div>
          </template>
          <template
            v-if="stock_info.AvailableInventory < qty"
          >
            <div class="list-item text-left">
              <span v-html="$t('delivery_time_backorder')"></span>: {{ stock_info.LeadTimeCalculation }}
            </div>
          </template>
        </div>
      </div>
  </div>
</template>

<script>
import Vue from "vue";
import VTooltip from 'v-tooltip'

export default {
  components: {
    VTooltip
  },
  name: "ProductInfo",
  props: ["discount_percentage", "product_id", "price", "pim_code", "translation", "discount_group", "logged_in", "language_code"],
  data() {
    return {
      loading: false,
      isLoggedIn: false,
      shopping: false,
      qty: 1,
      stock_info: {}
    };
  },
  computed: {
    todayInTime() {
      let date = new Date();
      return date.getHours() < 15 && date.getDay() != 0 && date.getDay() != 6;
    }
  },
  mounted() {
    this.getStockInfo();
    this.getItems();
  },
  methods: {
    formatPrice(price) {
      if (this.isLoggedIn !== false || price !== "Op aanvraag") {
        return Intl.NumberFormat("nl-NL", {
          style: "currency",
          currency: "EUR",
        }).format(price);
      }
    },
    plus() {
      this.qty++;
    },
    minus() {
      if (this.qty > 1) this.qty--;
    },
    async addItem() {
      let qty = parseInt(this.qty);
      let response = await Vue.http.post("/offertelijst/add", {
        id: this.product_id,
        qty: qty,
      });
      if (!response) {
        this.shopping = false;
        return false;
      }

      this.$store.dispatch("header_cart/getItems");

      this.shopping = true;
      return true;
    },
    async getItems() {
      this.loading = true;
      let response = await Vue.http.get("/offertelijst/get");
      if (!response) return false;
      this.isLoggedIn = response.body.isLoggedIn;
      this.loading = false;
    },
    async getStockInfo() {
      this.loading = true;
      let response = await Vue.http
        .get("/shop/getStockInfo/" + this.pim_code)
        .catch(function (e) {
          return false;
        });
      if (!response) {
        this.stock_info = false;
        return false;
      }
      this.stock_info = response.data;
      this.loading = false;
    },
  },
};
</script>
